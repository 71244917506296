import './App.css';
import React, { useState } from 'react'
import MainPage from './components/mainPage/mainPage';
import Header from './components/header/header';
import AgeGate from './components/ageGate/ageGate';

function App() {

  const [ageGate, setAgeGate] = useState(localStorage.getItem("ageGate") || "false")
  const handleAgeGate = (value) => setAgeGate(value)

  return (
    <div className="App">

      {ageGate === "true" &&
        <div className="soonStarting">
          <div className="soonMain">Ускоро!</div>
          <div className="soonText">
            Наградна игра траје од  <br />
            14.04. до 23.05.2025. године.
          </div>

          <div className="soonText soonTextMobile">
            Наградна игра траје од <br />
            од 14.04. до 23.05.2025.
          </div>
        </div>}

      {ageGate !== "true" &&
        <div className="soonStarting">
          <AgeGate handleAgeGate={handleAgeGate} />
        </div> }


      <MainPage />
      <Header />
    </div>
  );
}

export default App;
