import React from 'react'
import "./mainPage.css"
import leftBanner from "../../images/leftBanner.png"
import mainTitle from "../../images/mainTitle.png"
import pictograms from "../../images/pictograms.png"
import desktopCar from "../../images/desktopCar.png"
import desktopVoucher from "../../images/desktopVoucher.png"
import mobilePictograms from "../../images/mobilePictograms.png"
import desktopGlass from "../../images/desktopGlass.png"
import desktopGarden from "../../images/desktopGarden.png"
import igIcon from "../../images/igIcon.svg"
import fbIcon from "../../images/fbIcon.svg"

function MainPage() {
    return (
        <div className="mainPage">
            <img src={leftBanner}
                className="leftBanner" />
            <img src={pictograms}
                className="pictograms" />

            <div className="content">

                <div>
                    <img src={desktopCar} className="carImage" />
                    <div className="textHolder">
                        <div className="textItem">
                            <div>СВАКИХ 10 ДАНА</div>
                            <div>ШКОДА ОКТАВИЈА</div>
                        </div>
                        <div className="textItem">
                            <div>СВАКИХ 5 ДАНА</div>
                            <div>ВАУЧЕР ОД 2000 ЕВРА <br />
                                ЗА ОПРЕМАЊЕ КУХИЊЕ
                            </div>
                        </div>
                        <div className="textItem">
                            <div>СВАКОГ ДАНА</div>
                            <div>БАШТЕНСКИ СЕТ</div>
                        </div>
                        <div className="textItem">
                            <div>СВАКИХ 30 МИНУТА</div>
                            <div>СЕТ ЧАША</div>
                        </div>
                    </div>

                    <div className='mobilePrizes'>
                            <img src={desktopVoucher} className="voucherImage" />
                        </div>
                </div>


                <div className="prizes">
                    <img src={desktopVoucher} className="voucherImage desktopPrizes" />
                    <img src={desktopGlass} className="fridgeImage desktopPrizes" />
                    <img src={desktopGarden} className="bagImage desktopPrizes" />
                </div>

                <div className='mobilePrizes'>
                    <img src={desktopGlass} className="fridgeImage" />
                    <img src={desktopGarden} className="bagImage" />
                </div>

                <div className="desktopIcons">
                    <a href="https://www.instagram.com/zajecarsko.pivo/" target='_blank'>
                        <img src={igIcon} />
                    </a>

                    <a href="https://www.facebook.com/pivo.zajecarsko" target='_blank'>
                        <img src={fbIcon} />
                    </a>
                </div>

                <div className="mobileFooter">
                    <img src={mobilePictograms}
                        className="mobilePictograms" />
                    <img src={mainTitle} className="mobileMainTitle" />

                    <div className="mobileIcons">
                        <a href="https://www.instagram.com/zajecarsko.pivo/" target='_blank'>
                            <img src={igIcon} />
                        </a>

                        <a href="https://www.facebook.com/pivo.zajecarsko" target='_blank'>
                            <img src={fbIcon} />
                        </a>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default MainPage

{/* */ }
